<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="position-relative-full viewer  flex-column justify-center z-index-popup-select"
			>
				<div
					class="bg-white flex-column radius-20 box text-left  position-relative box-popup-select"
				>
					<div class="pa-20">
						<div class="under-line flex-row justify-space-between items-center pb-20">
							<h3 class="size-px-20">
								<slot name="title"></slot>
							</h3>

							<div class="">
								<button
									@click="$emit('cancel')"
								><v-icon>mdi-close</v-icon></button>
							</div>
						</div>
						<div class="">
							<ul class="box-select pt-20">
								<slot name="list"></slot>
							</ul>
						</div>
					</div>
					<div
						v-if="is_confirm"
						class=""
					>
						<button
							class="btn btn-primary"
							@click="$emit('click')"
							:disabled="is_disabled"
						>확인</button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'PopupSelect'
	, props: ['is_confirm', 'is_disabled']
	, components: {PopupLayer}
}
</script>

<style>
	.box-popup-select { min-width: 320px; max-width: 95%; margin: 0 auto; }
</style>